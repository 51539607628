/* eslint-disable jsx-a11y/no-autofocus */
import { HTMLAttributes, HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { Text } from '~/vinovest-design-system/components';

import { Placeholder } from '../../LoadingSkeleton/Placeholder';

export interface InputProps
    extends Pick<
        InputHTMLAttributes<HTMLInputElement>,
        'placeholder' | 'autoComplete' | 'type' | 'onInput' | 'onFocus' | 'onBlur'
    > {
    label: string;
    name: string;
    // placeholder?: string;
    note?: string;
    // type?: HTMLInputTypeAttribute;

    hideLabel?: boolean;
    // autoComplete?: string;
    inputClasses?: string;
    inputContainerClasses?: string;
    value?: string;
    textClasses?: string;
    children?: React.ReactChild;
    autoFocus?: boolean;
    labelClasses?: string;
    inputStyle?: HTMLAttributes<HTMLInputTypeAttribute>['style'];
    inputProps?: HTMLAttributes<HTMLInputTypeAttribute>;
    hideError?: boolean;
}

export const Input = ({
    name,
    note,
    label,
    hideLabel,
    inputClasses,
    value,
    inputContainerClasses,
    labelClasses,
    textClasses,
    children,
    inputProps,
    hideError,
    ...htmlInputProps
}: InputProps) => {
    const { register } = useFormContext();

    const { errors } = useFormState();
    const hasError = !hideError && errors[name] !== undefined;
    const hideNote = hasError ? 'hidden' : '';
    return (
        <>
            <label
                htmlFor={name}
                className={`${hideLabel ? 'sr-only' : 'block'} uppercase text-12 pt-4 text-gray-500 font-mono ${
                    labelClasses ?? ''
                }`}
            >
                {label}
            </label>
            <div className={'flex flex-col justify-center overflow-visible'}>
                <div className={`${`${inputContainerClasses} flex`}`}>
                    <input
                        {...htmlInputProps}
                        autoFocus
                        {...register(name)}
                        id={name}
                        style={{ MozAppearance: 'textfield', appearance: 'textfield', WebkitAppearance: 'none' }}
                        // placeholder={placeholder}
                        // autoComplete={autoComplete}
                        className={`border-0 h-[50px] mt-1 align-center inline-flex px-4 text-16 min-w-full border-gray-300 focus:border-platinum-800 focus-visible:outline-none placeholder:text-16 ${inputClasses}`}
                        // type={type}
                        {...(value ? { value } : {})}
                        {...(inputProps as any)}
                    />
                    {children}
                </div>
            </div>
            <Placeholder renderCondition={!hideError}>
                <Text color={'text-red-600'} classnames={`mt-2 text-12 ${textClasses}`}>
                    {errors[name]?.message as string}
                </Text>
            </Placeholder>
            <Text color={'text-neutrals-400'} classnames={`mt-2 text-12 ${hideNote} ${textClasses}`}>
                {note}
            </Text>
        </>
    );
};
