import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Hidden from '~/assets/img/login/hidden.svg';
import Seen from '~/assets/img/login/shown.svg';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { baseRoutes } from '~/routes/base';
import { AnalyticsEventNames } from '~/tracking';
import { OktaAuthClient } from '~/utils/oktaAuthClient';
import { Button, Placeholder } from '~/vinovest-design-system/components';
import { Input } from '~/vinovest-design-system/components/Form/components/Input';

import { LoginLink } from './LoginLink';
import { FormValues } from './LoginPage';
import { useRouter } from 'next/router';

interface FormObject {
    email: string;
    password: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Invalid email'),
    password: Yup.string().required('Password is required').min(8, '')
});

export const LoginInputs = () => {
    const router = useRouter();
    const formMethods = useForm<FormObject>({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { handleSubmit, formState } = formMethods;

    const submit = async (values: FormValues) => {
        let { callback_pathname: callbackParam } = router.query;
        if (Array.isArray(callbackParam)) {
            // eslint-disable-next-line prefer-destructuring
            callbackParam = callbackParam[0];
        }
        if (callbackParam != null) {
            if (!callbackParam.startsWith('/')) {
                // invalid, only relative urls are allowed
                callbackParam = undefined;
            }
        }

        setLoading(true);
        setErrorMessage('');
        const okta = await OktaAuthClient.getInstance();
        PosthogHelper.captureEvent(AnalyticsEventNames.UserSelectLogin);
        try {
            const result = await okta.signInWithCredentials({ username: values.email, password: values.password });
            if (result.sessionToken) {
                PosthogHelper.captureEvent(AnalyticsEventNames.UserLogin);
                await okta.signInWithRedirect({
                    sessionToken: result.sessionToken
                });
            }
        } catch (e: unknown) {
            console.error(e);
            setErrorMessage('Invalid username or password');
        } finally {
            setLoading(false);
        }
    };

    const [show, setShow] = useState(false);

    return (
        <FormProvider {...formMethods}>
            <form
                className={' grid grid-cols-1 min-w-full'}
                action={'#'}
                method={'POST'}
                onSubmit={handleSubmit(submit)}
            >
                <div className={'flex flex-col sm:flex-row gap-4'}>
                    <div className={'flex-1 relative'}>
                        <Input
                            name={'email'}
                            labelClasses={'xs:hidden md:flex'}
                            inputClasses={
                                '!border rounded bg-neutrals-100 h-[50px] mt-1 align-center inline-flex  text-16 min-w-full xs:max-w-[125px]'
                            }
                            type={'email'}
                            placeholder={'Email address'}
                            label={'Email Address'}
                            autoComplete={'email'}
                            value={''}
                        />
                    </div>
                </div>
                <div className={'flex flex-col sm:flex-row gap-4'}>
                    <div className={'flex-1 relative'}>
                        <Input
                            name={'password'}
                            labelClasses={'xs:hidden md:flex'}
                            inputClasses={
                                '!border rounded bg-neutrals-100 h-[50px] mt-1 align-center inline-flex  text-16 min-w-full xs:max-w-[125px] ph-no-capture'
                            }
                            type={show ? 'text' : 'password'}
                            label={'Password'}
                            inputContainerClasses={`flex items-center justify-center relative`}
                            placeholder={'Password'}
                        >
                            <button
                                type={'button'}
                                onClick={() => {
                                    setShow(!show);
                                }}
                                className={' absolute right-3 xs:top-[15px] md:top-[17px]'}
                            >
                                <img
                                    alt={'show or hide password icon button'}
                                    src={!show ? Seen.src : Hidden.src}
                                    className={' h-[25px] w-[25px]'}
                                />
                            </button>
                        </Input>
                    </div>
                </div>
                <Placeholder renderCondition={Boolean(errorMessage)} placeholder={null}>
                    <span className={'font-vinovestmedium text-red-600 font-normal mt-2 text-12'}>{errorMessage}</span>
                </Placeholder>

                <div className={`flex`}>
                    <LoginLink
                        href={baseRoutes.resetPassword.href}
                        label={'forgot password'}
                        className={'!text-brown-600 text-11'}
                    />
                </div>
                <Button
                    tabIndex={0}
                    disabled={!formState.isValid || loading}
                    variant={'green'}
                    type={'submit'}
                    classnames={'flex flex-grow items-center min-w-[100%] justify-center mt-3 h-[60px]'}
                >
                    {loading ? 'Logging in...' : 'Login'}
                </Button>
            </form>
        </FormProvider>
    );
};
