import { PosthogHelper } from '~/helpers/PosthogHelper';
import { AnalyticsEventNames } from '~/tracking';
import { OktaAuthClient } from '~/utils/oktaAuthClient';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';

const ssoOidcRedirectConfig = {
    display: 'page',
    response_type: 'code',
    response_mode: 'query',
    scope: 'openid%20email%20profile',
    nonce: 'YsG76j5',
    redirect: typeof window !== 'undefined' && `${window.location.origin}/implicit/callback`,
    client_id: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID
};

const googleRedirectParams = {
    idp: process.env.NEXT_PUBLIC_GOOGLE_OATH_OIDC_ID,
    ...ssoOidcRedirectConfig
};

const appleRedirectParams = {
    idp: process.env.NEXT_PUBLIC_APPLE_OAUTH_OIDC_ID,
    ...ssoOidcRedirectConfig
};

/**
 * Helper functions that encapsulate the initiation of the social login flow.
 */
export const SocialLoginHelper = {
    onGoogleSignIn: () => {
        OktaAuthClient.getInstance()
            .then((okta) => {
                PosthogHelper.captureEvent(AnalyticsEventNames.UserLogin);
                void okta.signInWithRedirect(googleRedirectParams);
            })
            .catch((error) => {
                console.error('Error while trying to sign in with Google', error);
                SentryHelper.captureException(error);
            });
    },
    onAppleSignIn: () => {
        OktaAuthClient.getInstance()
            .then((okta) => {
                PosthogHelper.captureEvent(AnalyticsEventNames.UserLogin);
                void okta.signInWithRedirect(appleRedirectParams);
            })
            .catch((error) => {
                console.error('Error while trying to sign in with Google', error);
                SentryHelper.captureException(error);
            });
    }
};
