import { FC } from 'react';

import { Placeholder } from '../LoadingSkeleton/Placeholder';

interface IconButtonProps {
    link?: string;
    containerClass?: string;
    description: string;
    buttonProps?: JSX.IntrinsicElements['button'];
    children?: React.ReactNode;
}

export const IconButton: FC<IconButtonProps> = ({ description, link, containerClass, buttonProps, children }) => {
    const isLink = !!link;
    const className =
        containerClass ||
        'flex justify-center items-center py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50';

    return (
        <>
            <span className={'sr-only'}>{description}</span>
            <Placeholder renderCondition={isLink} placeholder={null}>
                <a href={link} className={className}>
                    {children}
                </a>
            </Placeholder>
            <Placeholder renderCondition={!isLink} placeholder={null}>
                <button type={'button'} className={className} {...buttonProps}>
                    {children}
                </button>
            </Placeholder>
        </>
    );
};
