import { Logo } from '~/components/modules/Logo/Logo';
import { useWindowSize } from '~/hooks/useWindowSize';
import { Header } from '~/vinovest-design-system/components/Header/Header';

export const LoginHeader = () => {
    const { isMobile } = useWindowSize();

    return (
        <>
            {!isMobile && (
                <nav className={`shadow-none lg:shadow-none bg-white max-h-[75px] flex flex-grow min-w-full`}>
                    <Header
                        containerClasses={'max-h[75px] md:ml-[64px]'}
                        leftClass={'max-h-[75px]'}
                        left={
                            <div className={'flex flex-grow justify-start min-w-full'}>
                                <Logo
                                    type={'standard'}
                                    linkProps={{ className: 'max-h-[75px]' }}
                                    className={'!max-h-[75px] !max-w-[75px] w-[75px] h-[75px]'}
                                    width={'75'}
                                    height={'75'}
                                />
                            </div>
                        }
                    />
                </nav>
            )}
        </>
    );
};
