import 'yup-phone-lite';

import * as Yup from 'yup';
import { AnyObject, MixedSchema, StringSchema } from 'yup';

/** Makes form validation for use with react-form-hook */
export const makeFormValidation = (
    key: string,
    validation: StringSchema<string | undefined, AnyObject> | MixedSchema<any, AnyObject>
) => {
    return Yup.object().shape({
        [key]: validation
    });
};

export const fullNameValidation = Yup.string()
    .required('name required')
    .matches(/([\p{L}-]+ )([\p{L}-])/gu, 'First and last name is required');

export const passwordValidation = Yup.string()
    .required('Password is required')
    .matches(
        /^.*(?=.{8,})((?=.*[!#$%&()*+,.:;<=>@^_`{|}~¡¢£¥§¨©ª¬®´µ¶ºßåæç÷øœƒˆ˙˚π–‘“†•…™∂∆∑√∞∫≈≠≤≥\-]))(?=.*\d)((?=.*[a-z]))((?=.*[A-Z])).*$/,
        ''
    );

export const passwordLoginValidation = Yup.string()
    .required('Password is required')
    .matches(/^.*(?=.{8,}).+$/, 'sadasdads');

const isEmail = (string: string) => /^\S+@\S+\.\S+$/.test(string);
export const emailValidation = Yup.string()
    .required('Email is required')
    .test('Should contain valid email', 'Should contain valid email', (value) => isEmail(value));

export const phoneValidation = Yup.string().phone().required('Phone number is required');
