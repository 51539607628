import Link from 'next/link';

import { Text } from '~/vinovest-design-system/components';

export const LoginLink = ({
    href,
    label,
    className
}: {
    href: string;
    label: string;
    className?: HTMLSpanElement['className'];
}) => {
    return (
        <Link href={href}>
            <Text variant={'label'} as={'span'} classnames={className ?? 'text-11'} uppercase>
                {label}
            </Text>
        </Link>
    );
};
