import Image from 'next/legacy/image';

import Apple from '~/assets/img/social/apple.svg';
import Google from '~/assets/img/social/google.svg';
import { LoginInputs } from '~/components/views/Login/LoginInputs';
import { IconButton } from '~/vinovest-design-system/components/IconButton';

import { SocialLoginHelper } from './SocialLoginHelper';
import { LocalStorageHelper } from '~/helpers/StorageHelper';
import { useEffect } from 'react';

const iconButtonContainerClass =
    'flex justify-center items-center py-3 border border-gray-300 rounded-md  bg-white text-sm font-medium text-gray-500 hover:bg-gray-50';

export const LoginForm = () => {
    useEffect(() => {
        LocalStorageHelper.removeItem('is_new_user_phone');
    }, []);

    return (
        <div className={'bg-white sm:rounded-lg sm:px-10 flex flex-col'}>
            <LoginInputs />
            <div className={'mt-4 min-w-full'}>
                <div
                    className={'vinovest-mono text-13 align-center flex justify-center bg-gray-200 h-[1px] mt-8 mb-10'}
                >
                    <span className={'text-gray-400 bg-white px-2 inline-block -mt-[8px]'}>OR</span>
                </div>
                <div className={'mt-4 grid grid-cols-2 gap-4'}>
                    <IconButton
                        description={'Sign in with Google'}
                        containerClass={iconButtonContainerClass}
                        buttonProps={{
                            onClick: SocialLoginHelper.onGoogleSignIn
                        }}
                    >
                        <Image src={Google} width={22} height={22} />
                    </IconButton>

                    <IconButton
                        description={'Sign in with Apple'}
                        containerClass={iconButtonContainerClass}
                        buttonProps={{
                            onClick: SocialLoginHelper.onAppleSignIn
                        }}
                    >
                        <Image src={Apple} width={22} height={27} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
