import { FormProvider, useForm } from 'react-hook-form';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import { Logo } from '~/components/modules/Logo/Logo';
import { LoginForm } from '~/components/views/Login/LoginForm';
import { baseRoutes } from '~/routes/base';
import { Header, Heading, Text } from '~/vinovest-design-system/components';

import { LoginHeader } from './LoginHeader';
import { emailValidation, passwordLoginValidation } from '../SignupNew/components/validations';

const validationSchema = Yup.object().shape({
    email: emailValidation,
    password: passwordLoginValidation
});

export interface FormValues {
    email: string;
    password: string;
}

export default function LoginPage() {
    const router = useRouter();

    const methods = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    });

    return (
        <FormProvider {...methods}>
            <div className={'h-full w-full '}>
                <LoginHeader />
                <div className={'min-h-full flex flex-grow px-[20px] md:px-0'}>
                    <div
                        className={
                            'flex md:px-10 flex-col sm:flex-1 justify-center mx-auto md:mx-8 min-w-full md:min-w-[25rem] self-start md:mt-16'
                        }
                    >
                        <Header
                            containerClasses={'visible md:hidden lg:hidden'}
                            left={
                                <button aria-label={'back'}>
                                    <ArrowSmLeftIcon
                                        onClick={() => router.back()}
                                        className={'w-[25px] h-auto md:invisible self-'}
                                    />
                                </button>
                            }
                            middleClass={'items-center flex justify-center'}
                            middle={
                                <Logo
                                    type={'standard'}
                                    linkProps={{ className: 'flex justify-middle self-middle justify-self-middle' }}
                                    className={'w-[60px] h-[60px] md:w-[90px] md:h-[90px] inline-flex self-middle'}
                                    width={'60'}
                                />
                            }
                        />

                        <div className={'peer sm:mx-auto sm:w-full sm:max-w-lg md:px-10 px-2'}>
                            <Heading level={'h1'} type={'h2'} classnames={'text-center xs:pt-8 pb-0 mb-0'}>
                                Log in
                            </Heading>
                            <div className={'mx-auto text-center mt-2 xs:pb-8 lg:pb-12'}>
                                <Text classnames={'text-13'}>
                                    Don’t have an account yet?{' '}
                                    <Link href={baseRoutes.signup.href}>
                                        <span className={'text-yellow-700 hover:underline'}>Create one here</span>
                                    </Link>
                                </Text>
                            </div>
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
}
